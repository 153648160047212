import { MouseEvent, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";

interface NavLinkProps {
  handleMobileNav?: (event: MouseEvent<HTMLDivElement>) => void;
  isNavExpanded: boolean;
}

const Navlinks = ({ handleMobileNav, isNavExpanded }: NavLinkProps) => {
  const [serve, setServe] = useState<Boolean>(false);
  const handleServices = () => {
    setServe(!serve);
  };
  return (
    <div className="z-50">
      <div
        className={
          serve
            ? "absolute top-[15rem] left-[8rem] z-50 bg-white flex flex-col"
            : "hidden"
        }
      >
        <Link
          to="/services/forex"
          className="border-b border-black w-64 text-center py-6 text-blue font-semibold"
        >
          Forex Trading
        </Link>
        <Link
          to="/services/real-estate"
          className="border-b border-black w-64 text-center py-6 text-blue font-semibold"
        >
          Real Estate
        </Link>
        <Link
          to="/services/crypto"
          className="border-b border-black w-64 text-center py-6 text-blue font-semibold"
        >
          Cryptocurrency
        </Link>
      </div>
      <div
        onClick={handleMobileNav}
        className={
          isNavExpanded
            ? "z-10 absolute top-[4rem] left-[0] w-[70%] bg-gray-200 h-screen justify-center slideIn"
            : "z-10 absolute top-[4rem] left-[0] w-[70%] bg-gray-200 h-screen justify-center slideOut hidden"
        }
      >
        <nav className="w-full bg-gray-200">
          <Link
            to="/"
            className="flex items-center gap-x-3 border-b border-black pl-8 py-6 text-primary font-semibold cursor-pointer hover:bg-primary hover:text-white"
          >
            Home
          </Link>
          <div className="flex items-center gap-x-3 border-b border-black pl-8 py-6 text-primary font-semibold cursor-pointer hover:bg-primary hover:text-white">
            <p>Services</p> <IoIosArrowDown onClick={handleServices} />
          </div>
          <Link
            to="/about"
            className="flex items-center gap-x-3 border-b border-black pl-8 py-6 text-primary font-semibold cursor-pointer hover:bg-primary hover:text-white"
          >
            About
          </Link>
          <Link
            to="/plans"
            className="flex items-center gap-x-3 border-b border-black pl-8 py-6 text-primary font-semibold cursor-pointer hover:bg-primary hover:text-white"
          >
            Plans
          </Link>
          <Link
            to="/contact"
            className="flex items-center gap-x-3 border-b border-black pl-8 py-6 text-primary font-semibold cursor-pointer hover:bg-primary hover:text-white"
          >
            Contact
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default Navlinks;
