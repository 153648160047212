import { useEffect, useState } from "react";
import { RiArrowUpSFill } from "react-icons/ri";

function ScrollUpButton(): JSX.Element {
  const [showButton, setShowButton] = useState<boolean>(false);
  const [prevScrollPos, setPrevScrollPos] = useState<number>(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (): void => {
    const currentScrollPos = window.pageYOffset;

    if (prevScrollPos > currentScrollPos || currentScrollPos <= 200) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  const handleClick = (): void => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button
      id="scrollUpBtn"
      className={`fixed z-50 bottom-6 left-6 p-2 rounded-full bg-gray-500 text-white text-3xl hover:bg-gray-600 transition ease-in-out duration-300 ${
        showButton ? "block scrollIn" : "hidden"
      }`}
      onClick={handleClick}
    >
      <RiArrowUpSFill />
    </button>
  );
}

export default ScrollUpButton;
