import { verified } from "../data/data";
import Title from "./Title";

const Verified = () => {
  return (
    <section className="">
      <Title title="Secure" />
      <div className="grid justify-center items-center md:grid-cols-2 lg:grid-cols-4 py-5 gap-y-10 lg:px-44">
        {verified.map((data) => (
          <img
            key={data.id}
            src={data.img}
            alt={data.alt}
            className="w-[5rem] h-auto mx-auto"
          ></img>
        ))}
      </div>
    </section>
  );
};

export default Verified;
