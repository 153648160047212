import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import slide1 from "../assets/images/slide1.jpg";
import slide2 from "../assets/images/slide2.jpg";
import slide3 from "../assets/images/slide3.jpg";

const Hero: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
  };

  const slides = [
    {
      id: 1,
      url: slide1,
      title: "Welcome to Signal Pulse Investments",
      sub: "a secure & smart way to invest.",
      button1: "get started",
      button2: "login",
    },
    {
      id: 2,
      url: slide2,
      title: "Smart Financing Solutions",
      sub: "dedicated to providing ideal financing options.",
      button1: "get started",
      button2: "login",
    },
    {
      id: 3,
      url: slide3,
      title: "We trade, you profit",
      sub: "accelerate your business portfolio growth.",
      button1: "get started",
      button2: "login",
    },
  ];

  const navigate = useNavigate();

  const handleSignUp = () => {
    navigate("/signup");
  };

  return (
    <div className="hero-slider w-full h-screen overflow-hidden relative">
      <Slider {...settings}>
        {slides.map((slide) => (
          <div key={slide.id}>
            <div
              className="h-screen bg-cover bg-center flex items-center justify-center"
              style={{ backgroundImage: `url(${slide.url})` }}
            >
              <div className="backdrop-brightness-50 w-full h-full flex justify-center items-center">
                <div className="text-center text-white px-4">
                  <h1 className="text-4xl font-bold md:text-5xl lg:text-6xl">
                    {slide.title}
                  </h1>
                  <p className="text-xl md:text-2xl mt-4">{slide.sub}</p>
                  <div className="self-center mt-5 lg:mt-10 mx-auto">
                    <button
                      onClick={handleSignUp}
                      className="uppercase font-thin text-sm bg-primary px-4 py-2 md:px-6 md:py-4 hover:bg-white cursor-pointer rounded-md text-white hover:text-primary"
                    >
                      get started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Hero;
