import { useState } from "react";
import Remarks from "../components/Remark";
import { faq } from "../data/data";
import Title from "./Title";

const Feedback: React.FC = () => {
  const [spin, setSpin] = useState<any>(null);

  const handleToggle = (i: number) => {
    if (spin === i) {
      return setSpin(null);
    }
    setSpin(i);
  };

  return (
    <section className="py-20">
      <Title title="FAQ" sub="Frequently asked questions" />
      <div>
        {faq.map((data, i) => (
          <div
            key={data.id}
            className="w-[80%] p-0.5 my-8 rounded-2xl mx-auto bg-gradient-to-r from-[#4895ef] via-[#4361ee] to-[#3f37c7]"
          >
            <div className="w-full h-full bg-secondary p-4 rounded-2xl">
              <div className="flex justify-between items-center">
                <p className="text-lg font-medium">{data.question}</p>
                <span
                  className="text-2xl text-blue cursor-pointer"
                  onClick={() => handleToggle(i)}
                >
                  {data.icon}
                </span>
              </div>
              <div className={spin === i ? "block mt-3" : "hidden"}>
                <p>{data.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        <Remarks />
      </div>
    </section>
  );
};

export default Feedback;
