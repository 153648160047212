import { useNavigate } from "react-router-dom";
import shape1 from "../assets/images/shape1.png";
import shape2 from "../assets/images/shape2.png";
import { features } from "../data/data";
import Button from "./Button";

const Features = () => {
  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate("/signup");
  };

  return (
    <section className="mt-20">
      <div className="">
        <p className="font-sofia text-xl text-center tracking-widest lg:text-4xl">
          Our Features
        </p>
        <div className="w-14 h-1 bg-gradient-to-r from-[#4895ef] via-[#4361ee] to-[#3f37c7] mx-auto mt-4"></div>
        <div className="">
          <div
            className="bg-no-repeat bg-auto bg-top-left md:bg-left"
            style={{
              backgroundImage: `url(${shape2})`,
            }}
          >
            <div
              className="bg-no-repeat bg-auto bg-right-bottom md:bg-right "
              style={{
                backgroundImage: `url(${shape1})`,
              }}
            >
              <div className="pt-10 px-3 md:px-20 md:grid md:grid-cols-3">
                {features.map((feature) => (
                  <div
                    key={feature.title}
                    className="flex flex-col justify-center items-center text-center py-10 px-4"
                  >
                    <div className="border border-gray-200 border-dashed rounded-full w-32 h-32 flex justify-center items-center text-4xl text-blue">
                      {feature.icon}
                    </div>
                    <p className="capitalize pt-6 pb-3 font-semibold">
                      {feature.title}
                    </p>
                    <p>{feature.desc}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="py-10 flex justify-center">
            <Button children="Sign up" primary={true} onClick={handleSignUp} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
