import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import contactReducer from "./features/contact/contactReducer";
import depositReducer from "./features/deposit/depositReducer";
import userReducer from "./features/users/userReducer";
import debitReducer from "./features/withdrawal/withdrawalReducer";

export const store = configureStore({
  reducer: {
    user: userReducer,
    deposit: depositReducer,
    contact: contactReducer,
    debit: debitReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AddDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
