import { Link } from "react-router-dom";

const AboutComp = () => {
  return (
    <div className="relative z-20 pb-10 border-b border-gray-400">
      <div className="shadow-2xl absolute bg-white mx-8 md:mx-20 p-4 md:p-8 text-lg rounded-xl top-[27%] lg:top-[25%] z-50">
        <strong className="inline-block"> Signal Pulse Investments </strong> is
        a fully functional company with investment services ranging from
        Cryptocurrency investment, Forex trading, Stocks & Commodities
        investment, & Real Estate investment. Our company provides acquisition,
        development and finance expertise for both commercial and individual
        investors. <br /> <br />
        We are committed to making sure you achieve your investment goals, with
        our full time mining network, professional traders and affiliate system
        at your disposal. <br /> <br />
        <Link to="/about" className="text-primary">
          Read More...
        </Link>
      </div>
      {/* <div className="clip-rectangle bg-gradient-to-r from-[#4895ef] via-[#4361ee] to-[#3f37c7] h-[40rem] lg:h-[35rem] w-full"> */}
      <div className="clip-rectangle bg-[#1A237E] h-[40rem] lg:h-[35rem] w-full">
        <p className="text-center py-16 text-3xl text-white font-sofia tracking-widest">
          About
        </p>
      </div>
    </div>
  );
};

export default AboutComp;
