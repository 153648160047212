import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Contact } from "../../interfaces/contactInterface";

const API_URL = "https://signalpulse.vercel.app/";
// const API_URL = "/";

interface ContactState {
  contact: Contact | [];
  error: boolean;
  success: boolean;
  loading: boolean;
  message: any;
}

const initialState: ContactState = {
  contact: [],
  error: false,
  message: "",
  success: false,
  loading: false,
};

//contact
export const contact = createAsyncThunk<Contact, Object>(
  "/contact",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(API_URL + "contact", data);
      return response.data;
    } catch (error: any) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: { reset: (state) => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(contact.pending, (state) => {
        state.loading = true;
      })
      .addCase(contact.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.contact = action.payload;
      })
      .addCase(contact.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = contactSlice.actions;
const contactReducer = contactSlice.reducer;
export default contactReducer;
