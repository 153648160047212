import classnames from "classnames";
import React from "react";

type ButtonProps = {
  secondary?: boolean;
  primary?: boolean;
  tertiary?: boolean;
  disabled?: boolean;
  children: string;
  onClick: () => void;
};

const Button: React.FC<ButtonProps> = ({
  primary,
  secondary,
  tertiary,
  disabled,
  onClick,
  children,
}) => {
  const buttonClasses = classnames(
    "py-1.5 text-center font-semibold rounded-full shadow-md",
    {
      "text-white whitespace-nowrap cursr-pointer w-32 lg:w-44 h-10 font-thin hover:border hover:border-white bg-[#1A237E]":
        primary,
      "text-white whitespace-nowrap !py-1 px-5 rounded-none font-thin hover:border hover:border-black bg-primary":
        tertiary,
      "bg-white text-primary cursor-pointer font-thin w-32 lg:w-44 h-10 hover:border hover:border-primary hover:text-primary whitespace-nowrap":
        secondary,
      "bg-gray-400 text-gray-600 border-gray-400 cursor-not-allowed opacity-50":
        disabled,
    }
    // const buttonClasses = classnames(
    //   "py-1.5 text-center font-semibold rounded-full shadow-md",
    //   {
    //     "text-white whitespace-nowrap w-32 lg:w-44 font-thin hover:border hover:border-white bg-gradient-to-r from-[#3f37c7] via-[#4361ee] to-[#4895ef]":
    //       primary,
    //     "text-white whitespace-nowrap !py-1 px-5 rounded-none font-thin hover:border hover:border-black bg-gradient-to-r from-[#3f37c7] via-[#4361ee] to-[#4895ef]":
    //       tertiary,
    //     "bg-white text-blue cursor-pointer font-thin w-32 lg:w-44 hover:bg-blue hover:text-white whitespace-nowrap":
    //       secondary,
    //     "bg-gray-400 text-gray-600 border-gray-400 cursor-not-allowed opacity-50":
    //       disabled,
    //   }
  );

  return (
    <button className={buttonClasses} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
