import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Deposit } from "../../interfaces/depositInterface";

const API_URL = "https://signalpulse.vercel.app/user/";
// const API_URL = "/user/";

interface DepositState {
  deposit: Deposit | [];
  error: boolean;
  success: boolean;
  loading: boolean;
  message: any;
}

const initialState: DepositState = {
  deposit: [],
  error: false,
  message: "",
  success: false,
  loading: false,
};

//deposit
export const deposit = createAsyncThunk<Deposit, Object>(
  "user/deposit",
  async (data, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.post(API_URL + "deposit", data, config);
      return response.data;
    } catch (error: any) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get deposit
export const getUserDeposit = createAsyncThunk<any>(
  "user/getUserDeposit",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.get(API_URL + "deposit", config);
      return response.data;
    } catch (error: any) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const depositSlice = createSlice({
  name: "deposit",
  initialState,
  reducers: { reset: (state) => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(deposit.pending, (state) => {
        state.loading = true;
      })
      .addCase(deposit.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.deposit = action.payload;
      })
      .addCase(deposit.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
      })

      //getUserDeposit
      .addCase(getUserDeposit.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserDeposit.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.deposit = action.payload;
      })
      .addCase(getUserDeposit.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = depositSlice.actions;
const depositReducer = depositSlice.reducer;
export default depositReducer;
