import { useEffect, useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import quote from "../assets/images/quote2.png";
import { remarks } from "../data/data";
import Title from "./Title";

const Remarks = () => {
  const [slider, setSlider] = useState<number>(0);
  const goToNext = (slider: number) => {
    setSlider(slider);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (slider === 3) {
        setSlider(0);
      } else {
        setSlider(slider + 1);
      }
    }, 5000);
    return () => clearTimeout(timer);
  });

  return (
    <section className="relative">
      <div className="mt-20 lg:mt-32">
        <Title title="Reviews Corner" />
      </div>
      <div className="bg-primary text-white px-10 h-[22rem] mt-10 py-10">
        <div
          className="relative bg-white text-black flex flex-col justify-center items-center my-2 rounded-xl max-w-[30rem] h-[15rem] p-6 lg:p-10 mx-auto bg-no-repeat bg-cover bg-bottom-right"
          style={{ backgroundImage: `url(${quote})` }}
        >
          <div className="flex self-start justify-center items-center gap-5">
            <img
              src={remarks[slider].img}
              alt="Remarks"
              className="h-14 w-14 rounded-full border border-blue"
            />
            <span>
              <p className="font-semibold">{remarks[slider].name}</p>
              <p className="font-light">{remarks[slider].position}</p>
            </span>
          </div>
          <p className="mt-5">{remarks[slider].comment}</p>
        </div>
      </div>
      <div className="absolute top-[50%] w-full p-4 hidden lg:block">
        <button
          className="text-white/80 p-1 absolute left-[5%] shadow rounded-full text-white hover:bg-white hover:text-black"
          onClick={() =>
            setSlider((slider) =>
              slider === 0 ? remarks.length - 1 : slider - 1
            )
          }
        >
          <BsChevronLeft size={40} />
        </button>
        <button
          className="text-white/80 p-1 absolute right-[5%] self-start shadow rounded-full text-white hover:bg-white hover:text-black"
          onClick={() =>
            setSlider((slider) =>
              slider === remarks.length - 1 ? 0 : slider + 1
            )
          }
        >
          <BsChevronRight size={40} />
        </button>
      </div>

      <div className="absolute bottom-4 left-0 right-0">
        <div className="flex items-center justify-center gap-2">
          {remarks.map((_, i) => (
            <div
              key={_.id}
              onClick={() => goToNext(i)}
              className={`
              transition-all w-7 h-2 bg-white rounded-sm hover:cursor-pointer
              ${slider === i ? "h-3 bg-white" : "bg-opacity-50"}
            `}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Remarks;
