import { AiOutlineMail } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import img from "../../assets/images/logoSilver.png";
// import img from "../../assets/images/signalPulse.png";
import Button from "../../components/Button";

const Footer = () => {
  const navigate = useNavigate();

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleSignIn = () => {
    navigate("/signin");
  };

  return (
    <section className="w-full bg-primary py-10 px-3 md:px-10">
      {/* <section className="w-full bg-gradient-to-r from-[#1A237E] via-[#4361ee] to-[#4895ef] py-10 px-3 md:px-10"> */}
      {/* <section className="w-full bg-gradient-to-r from-[#4895ef] via-[#4361ee] to-[#3f37c7] py-10"> */}
      <div className="flex flex-col justify-center items-center">
        <div className="flex items-center">
          <img src={img} alt="logo" className="md:w-96" />
        </div>

        <div className="flex justify-center items-center gap-3 mt-5">
          <Button children="Sign up" secondary={true} onClick={handleSignUp} />
          <Button children="Login" primary={true} onClick={handleSignIn} />
        </div>
        <div className="text-white flex flex-col md:flex-row justify-center items-start w-full mt-10">
          <Link
            className="px-2 my-2 hover:border-white hover:text-slate-300 md:border-r-2"
            to="/about"
          >
            About
          </Link>
          <Link
            className="px-2 my-2 hover:border-white hover:text-slate-300 md:border-r-2"
            to="/faq"
          >
            FAQs
          </Link>
          <Link
            className="px-2 my-2 hover:border-white hover:text-slate-300 md:border-r-2"
            to="/plans"
          >
            Plans
          </Link>
          <Link
            className="px-2 my-2 hover:border-white hover:text-slate-300 md:border-r-2"
            to="/affiliate"
          >
            Affiliate
          </Link>
          <Link
            className="px-2 my-2 hover:border-white hover:text-slate-300 md:border-r-2"
            to="/contact"
          >
            Contact
          </Link>
          <Link
            className="px-2 my-2 hover:border-white hover:text-slate-300"
            to="/terms&conditions"
          >
            Terms & Conditions
          </Link>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between text-white mt-10">
        <a
          href="mailto:support@signalpulseinvestments.com"
          className="flex items-center gap-x-1 cursor-pointer hover:text-slate-300"
        >
          <AiOutlineMail />
          <p>support@signalpulseinvestments.com</p>
        </a>
        <p className="mt-2 md:mt-0">© 2024 signalpulseinvestments.com</p>
      </div>
    </section>
  );
};

export default Footer;
