import classnames from "classnames";
import { AiOutlineClockCircle, AiOutlinePlus } from "react-icons/ai";
import { BiBarChart, BiHomeCircle } from "react-icons/bi";
// BiPowerOff;
import {
  BsArrowLeftRight,
  BsBoxArrowInDown,
  BsBoxArrowUp,
  BsCreditCard2Back,
} from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { ImFileText2 } from "react-icons/im";
import { IoIosArrowDown } from "react-icons/io";
import {
  IoFileTrayFullOutline,
  IoHardwareChipOutline,
  IoLocationSharp,
  IoMailOutline,
  IoThumbsUpOutline,
} from "react-icons/io5";
import {
  MdOutlineDragIndicator,
  MdOutlineHistory,
  MdOutlineRocket,
  MdSecurity,
} from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";
import { TfiWorld } from "react-icons/tfi";
import bitcoin from "../assets/images/bitcoin.png";
import btc from "../assets/images/btc.jpg";
import crypto from "../assets/images/crypto.jpg";
import ddos from "../assets/images/ddos.png";
import eth from "../assets/images/eth.jpg";
import ethereum from "../assets/images/ethereum.png";
import norton from "../assets/images/norton.png";
import realEstate from "../assets/images/realEstate.jpg";
import rem1 from "../assets/images/rem1.jpg";
import rem2 from "../assets/images/rem2.jpg";
import rem3 from "../assets/images/rem3.jpg";
import rem4 from "../assets/images/rem4.jpg";
import secure from "../assets/images/secure.png";
import slide1 from "../assets/images/slide1.jpg";
import slide2 from "../assets/images/slide2.jpg";
import slide3 from "../assets/images/slide3.jpg";
import ssl from "../assets/images/ssl.jpg";
import tether from "../assets/images/tether.jpg";
import trading from "../assets/images/tradingg.jpg";
import usdt from "../assets/images/usdt.jpg";

export const gradient = classnames(
  "bg-gradient-to-r from-[#4895ef] to-[#3f37c7]"
);

export const navlinks = [
  { name: "Home", to: "/home" },
  { name: "Services", icon: <IoIosArrowDown />, to: "/services" },
  { name: "About", to: "/about" },
  { name: "Plans", to: "/plans" },
  { name: "Contact", to: "/contact" },
];

export const slides = [
  {
    id: 1,
    url: slide1,
    title: "Welcome to Signal Pulse Investments",
    sub: "a secure & smart way to invest",
    button1: "get started",
    button2: "login",
  },
  {
    id: 2,
    url: slide2,
    title: "Smart Financing Solutions",
    sub: "we are concerned with producing favorable financing solutions",
    button1: "get started",
    button2: "login",
  },
  {
    id: 3,
    url: slide3,
    title: "We trade, you profit",
    sub: "the best way to make your business portfolio mature faster.",
    button1: "get started",
    button2: "login",
  },
];

export const features = [
  {
    icon: <MdSecurity />,
    title: "strong security",
    desc: "Strong protection against DDoS attacks, full data encryption.",
  },
  {
    icon: <BsCreditCard2Back />,
    title: "payment options",
    desc: "We accept four popular payment method: Bitcoin, Ethereum, Tether and Tron.",
  },
  {
    icon: <TfiWorld />,
    title: "world coverage",
    desc: "Providing services to all countries around the globe.",
  },
  {
    icon: <IoThumbsUpOutline />,
    title: "24/7 support",
    desc: "Dedicated support via email, phone and live chat around the clock to answer your questions.",
  },
  {
    icon: <BiBarChart />,
    title: "professional charts",
    desc: "Real-time market data, as well as high-spec technical analysis tools satisfy the most demanding trade.",
  },
  {
    icon: <TbFileInvoice />,
    title: "investment management",
    desc: "Our Investment Management Services help you pursue your financial needs as they grow and change.",
  },
];

export const why = [
  {
    icon: <BiBarChart />,
    title: "Professional traders",
    desc: "A team of professional market traders who are profitable trading with cryptocurrency on the most popular world exchanges.",
  },
  {
    icon: <IoHardwareChipOutline />,
    title: "High-Quality Technical Expert",
    desc: "High-quality technical expert with knowledge and experience in the field in the field of internet technologies, digital marketing and sociological research.",
  },
  {
    icon: <BsCreditCard2Back />,
    title: "Instant Payouts",
    desc: "Payouts and withdrawals are processed as fast as possible with the help of our automated system so as to ensure investors receive their funds on time.",
  },
];

export const steps = [
  {
    number: "01",
    title: "Sign up",
    desc: "Create an account",
    empty: "",
  },
  {
    number: "02",
    title: "Deposit in Plan",
    desc: "Deposit in a preferred plan to start up your investment.",
    empty: "",
  },
  {
    number: "03",
    title: "Start Planning",
    desc: "Start earning profits and request for a withdrawal if you wish to withdraw.",
    empty: "",
  },
];

export const plans = [
  {
    price: "$200 - $999",
    // duration: "30 (days)",
    logo: <MdOutlineRocket />,
    highlight: <MdOutlineDragIndicator />,
    invest: "Free Consultation",
    percent: "2.5% daily ROI",
    benefit: "Membership Benefits",
    // trading: "-",
    // support: "-",
    title: "starter",
  },
  {
    price: "$1K - $4,999K",
    // duration: "30 (days)",
    logo: <MdOutlineRocket />,
    highlight: <MdOutlineDragIndicator />,
    invest: "Free Consultation",
    percent: "4% daily ROI",
    benefit: "Membership Benefits",
    // trading: "-",
    // support: "-",
    title: "premium",
  },
  {
    price: "$5K - $19,999K",
    // duration: "30 (days)",
    logo: <MdOutlineRocket />,
    highlight: <MdOutlineDragIndicator />,
    invest: "Free Consultation",
    percent: "6.5% daily ROI",
    benefit: "Membership Benefits",
    // trading: "-",
    // support: "-",
    title: "essential",
  },
  {
    price: "$20K - $49,999K",
    // duration: "30 (days)",
    logo: <MdOutlineRocket />,
    highlight: <MdOutlineDragIndicator />,
    invest: "Investment Advice",
    percent: "12% ROI in 3 days",
    benefit: "Membership Benefits",
    // trading: "-",
    // support: "-",
    title: "platinium",
  },
  {
    price: "$50K - $499,999K",
    // duration: "30 (days)",
    logo: <MdOutlineRocket />,
    highlight: <MdOutlineDragIndicator />,
    invest: "Investment Advice",
    percent: "15% ROI in 3 days",
    benefit: "Membership Benefits",
    trading: "Unlimited Trading",
    support: "Exclusive Mentorship",
    title: "crest",
  },
  {
    price: "$500K - unlimited",
    // duration: "30 (days)",
    logo: <MdOutlineRocket />,
    highlight: <MdOutlineDragIndicator />,
    percent: "25% ROI in 4 days",
    invest: "Investment Advice",
    benefit: "Membership Benefits",
    trading: "Unlimited Trading",
    support: "Exclusive Mentorship",
    title: "big stake",
  },
];

export const plan = [
  {
    title: "starter",
    minimum: "$200",
    maximum: "$999",
    profit: "2.5% daily ROI",
  },
  {
    title: "premium",
    minimum: "$1, 000",
    maximum: "$4, 999",
    profit: "4% daily ROI",
  },
  {
    title: "essential",
    minimum: "$5, 000",
    maximum: "$19, 999",
    profit: "6.5% daily ROI",
  },
  {
    title: "platinium",
    minimum: "$20, 000",
    maximum: "$49, 999",
    profit: "12% daily ROI",
  },
  {
    title: "crest",
    minimum: "$50, 000",
    maximum: "$499, 000",
    profit: "15% daily ROI",
  },
  {
    title: "big stake",
    minimum: "$500, 000",
    maximum: "unlimited",
    profit: "25% daily ROI",
  },
];

export const services = [
  {
    img: crypto,
    title: "Cryptocurrency Investment",
    desc: "It is emerging as the fresh financial frontier, creating ripples and delivering impressive returns on invested funds, earning recognition as the 'New Money' in the finance sector.",
  },
  {
    img: trading,
    title: "Forex Trading",
    desc: "Over time, the real estate industry has demonstrated innovation and profitability through the utilization of the Meta Trader 5 trading software.",
  },
  {
    img: realEstate,
    title: "Real Estate Investment",
    desc: "Engaging in real estate investment, even with minimal resources, continues to be an effective method for individuals to enhance their cash flow and accumulate wealth.",
  },
];

export const faq = [
  {
    question: "How do I become a part of this?",
    answer:
      "Becoming a part of this is simple—simply create an account or reach out to our customer care for assistance and we'll guide you through the process.",
    icon: <AiOutlinePlus />,
    id: 1,
  },
  {
    question: "Are my personal information safe with you?",
    answer:
      "Certainly! We prioritize the implementation of recommended safety measures to safeguard all submitted data, ensuring that it remains secure and inaccessible to unauthorized individuals.",
    icon: <AiOutlinePlus />,
    id: 2,
  },
  {
    question: "Are my funds secure?",
    answer:
      "Investors’ funds are held in segregated accounts, meaning completely separate from the company’s accounts. Investors’ funds are kept by ICC Intercertus Capital Limited in first class banks.",
    icon: <AiOutlinePlus />,
    id: 3,
  },
  {
    question: "How much do I need to start?",
    answer:
      "Currently, our minimum account size is $1,000 , although under some circumstances we will accept accounts less than $1,000. However, instead of looking to satisfy a minimum account size criteria, we are looking for long-term client relationships. It is important to us to establish a relationship with our clients where we are satisfied that our investment style will meet your needs over the long run.",
    icon: <AiOutlinePlus />,
    id: 4,
  },
  {
    question: "What approach to asset allocation do you use?",
    answer:
      "Our strategy is based on a long-term disciplined approach to investing. After reviewing your Client Profile and completing the client interview process, we can develop a portfolio and asset allocation strategy to meet your individual needs and tolerance for risk. We use tactical asset allocation in our ongoing management of your portfolio.",
    icon: <AiOutlinePlus />,
    id: 5,
  },
];

export const remarks = [
  {
    id: 1,
    img: rem1,
    name: "John Skittle",
    position: "Executive Chairman",
    comment:
      "We ensure that our customer service is at the highest level which includes both security and advice.",
  },
  {
    id: 2,
    img: rem3,
    name: "Austin Debree",
    position: "Investor",
    comment: "Hands down the best investment platform I've worked with.",
  },
  {
    id: 3,
    img: rem4,
    name: "Joyce Floyd",
    position: "Investor",
    comment: "I enjoyed so much ease, great customer care service!",
  },
  {
    id: 4,
    img: rem2,
    name: "David Hudson",
    position: "Investor",
    comment:
      "Invested with Signal Pulse Investments since I started my business and the profits incured has helped me widen my reach across the country.",
  },
];

export const verified = [
  { id: 1, img: secure, alt: "secure" },
  { id: 2, img: ddos, alt: "ddos" },
  { id: 3, img: ssl, alt: "ssl" },
  { id: 4, img: norton, alt: "norton" },
];

export const terms = [
  {
    question: "General Information",
    answer:
      "By accessing or using the Website and its services, the User agrees to be bound by the terms and conditions set forth, regardless of time or location. The Company reserves the right to suspend, modify, amend, or terminate access to the Website, its products, and services at its discretion, at any time, without any claims being considered. The Company may update the terms, information, and materials on the Website without prior notice, and it is the User's responsibility to stay informed of such changes. The User is obligated to comply with the laws, regulations, and directives of their country of residence and will be held accountable for any unauthorized or illegal use of the Website. The User acknowledges that the Website is not an offer to buy or sell any products or services in jurisdictions where doing so would be unlawful. As the Website is accessible globally, certain financial instruments mentioned may not be available for investment in all regions. In such cases, the User agrees to hold the Company harmless from any consequences that may arise. Trading in financial instruments involves risks, including the potential for both losses and gains.",
    icon: <AiOutlinePlus />,
    id: 1,
  },
  {
    question: "Right to correct, access and delete any data",
    answer:
      "Our customers have the right to access, correct, and delete their personal data, as well as to object to its processing, by submitting a written request at any time. The Company takes all necessary precautions to protect the security and privacy of personal data, aiming to prevent unauthorized access, alteration, corruption, or destruction. However, the Company acknowledges that it cannot eliminate all risks associated with Internet use and advises users of potential risks when using the Internet. The Site may contain links to external websites or other internet sources, which the Company does not control. Therefore, the Company is not responsible for the availability or content of these websites and external sources, nor for any advertisements, products, services, or other materials found on or accessed through them.",
    icon: <AiOutlinePlus />,
    id: 2,
  },
  {
    question: "Management of Personal Data",
    answer:
      "You can view or update your personal data online for many of our services and make choices regarding how your data is collected and used. The ability to access or control your personal data depends on the services you use. You can also decide whether to receive promotional communications from our website via email, SMS, physical mail, or phone. If you receive promotional emails or SMS messages and wish to unsubscribe, you can follow the instructions provided in the message. Additionally, you can manage your preferences for promotional emails, phone calls, and postal mail by logging into the Company’s Promotional Communications Manager. There, you can update your contact information, adjust preferences, opt out of email subscriptions, and decide whether to share your contact information with our partners. These options do not apply to essential service communications that are part of certain website services.",
    icon: <AiOutlinePlus />,
    id: 3,
  },
  {
    question: "Website Ownership and Legal Information",
    answer:
      "Signal Pulse Investment, accessible via www.signalpulseinvestments.com ('Website'), including all products and services offered by Swish Financial Services Ltd., is and remains the exclusive property of the Company, with all rights reserved. Swish Financial Services Ltd. owns all intellectual property rights, including trade names, trademarks, logos, and other exclusive assets. Unless expressly stated otherwise, the Company retains copyright over the HTML, text, graphics, and other materials on the Website. No individual or entity may alter, modify, reproduce, distribute, display, exploit, transmit, transcribe, copy, post, store, or translate any materials from the Website without prior written consent from Swish Financial Services Ltd. These terms and conditions complement any other agreements or documents between the Company and the User. The User assumes full responsibility and risk for using and storing information on their computer or other devices through which the Website is accessed..",
    icon: <AiOutlinePlus />,
    id: 4,
  },
  {
    question: "Data",
    answer:
      "The information available on the Website is intended solely for informational and personal use. Users may seek advice from independent investment, financial, legal, or tax advisors before making any investment decisions if they wish. The Company, along with its Employees, Officers, Directors, Affiliates, Agents, Service Providers, and Licensors, does not provide investment advice through the Website, and the information should not be interpreted as such. The Website does not account for any User’s specific investment goals, financial situation, or personal requirements. Individuals and entities should carefully assess their financial circumstances before engaging in any transaction(s) or agreement(s).",
    icon: <AiOutlinePlus />,
    id: 5,
  },
  {
    question: "Jurisdiction and Applcable Law",
    answer:
      "Without limiting the above, the User may not use the Website's content for commercial purposes, include it in or with any product created or distributed by the User, or copy it to their own or another User's website without prior permission from the Company. If any term or condition is found to be invalid or unenforceable for any reason, that specific provision will be excluded, and the remaining terms will still apply in full. Any translations of the Website’s content, including products and services, are provided as a courtesy for the User’s convenience. In case of any dispute between different language versions, the English version of the Website will take precedence.",
    icon: <AiOutlinePlus />,
    id: 6,
  },
];

export const contactDeets = [
  { icon: <IoLocationSharp />, message: "Detroit, Michigan. USA", id: 1 },
  {
    icon: <AiOutlineClockCircle />,
    message: "Mon - Fri",
    sub: "08:00am - 06:00pm",
    id: 2,
  },
  {
    icon: <IoMailOutline />,
    message: "support@signalpulseinvenments.com",
    id: 3,
  },
];

export const dashNav1 = [
  { icon: <BiHomeCircle />, name: "Dashboard", to: "/user/dashboard" },
];

export const dashNav2 = [
  { icon: <BsBoxArrowInDown />, name: "Deposit", to: "/user/deposit" },
  { icon: <BsBoxArrowUp />, name: "Withdraw", to: "/user/withdraw" },
];

export const dashNav3 = [
  { icon: <MdOutlineHistory />, name: "History", to: "/user/history" },
  {
    icon: <IoFileTrayFullOutline />,
    name: "Investment records",
    to: "/user/investment",
  },
  { icon: <ImFileText2 />, name: "Referral list", to: "/user/referral" },
];

export const dashNav4 = [
  {
    icon: <BsArrowLeftRight />,
    name: "Withdrawal Account",
    to: "/user/withdrawAccount",
  },
  { icon: <CgProfile />, name: "Profile", to: "/user/profile" },
];

export const payment = [
  {
    img: bitcoin,
    scan: btc,
    title: "Pay via BITCOIN",
    link: "bc1q8rl6mr6we8kwpynxdmu8r6g9ukrdrm4h04yxsp",
    to: "/user/deposit/pay/bitcoin",
    id: 1,
  },
  {
    img: ethereum,
    scan: eth,
    title: "Pay via ETHEREUM",
    link: "0x04a3a31c8f8DC025fDAfff8f9D78124d465319De",
    to: "/user/deposit/pay/ethereum",
    id: 2,
  },
  // {
  //   img: tether,
  //   scan: trc,
  //   title: "Pay  via TETHER (TRC20)",
  //   link: "TSwBkaw7BRM1YqMvUbqx1mwENQCXuaWkWF",
  //   to: "/user/deposit/pay/tether-trx",
  //   id: 4,
  // },
  {
    img: tether,
    scan: usdt,
    title: "Pay via TETHER (ERC20)",
    link: "0x04a3a31c8f8DC025fDAfff8f9D78124d465319De",
    to: "/user/deposit/pay/tether-erc",
    id: 3,
  },
  // {
  //   img: matic,
  //   title: "Pay  via MATIC",
  //   link: "ghsdgh,f;iuiesjhsdbjsd",
  //   to: "/user/deposit/pay/matic",
  //   id: 4,
  // },
];
