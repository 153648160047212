import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";

const Spinner = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const override = css`
    display: block;
    margin: 0 auto;
  `;

  return (
    <div className="flex justify-center items-center h-screen">
      <ClipLoader color="primary" size={100} />
    </div>
  );
};

export default Spinner;
