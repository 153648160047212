import { MouseEvent, useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logowtext from "../../assets/images/logoDark.png";
import logowtext2 from "../../assets/images/logoSilver.png";
import logo from "../../assets/images/signalPulse.png";
// import Button from "../../components/Button";
import Navlinks from "../../utils/Navlinks";

const Navbar = () => {
  const [navbar, setNavbar] = useState<boolean>(false);
  const [state, setState] = useState(false);
  const [serve, setServe] = useState<boolean>(false);
  const isNavExpanded = state;
  const navigate = useNavigate();

  const handleServices = () => {
    setServe(!serve);
  };

  const changeBackground = () => {
    if (window.scrollY >= 8) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  const handleMobileNav = (event: MouseEvent<HTMLDivElement>) => {
    setState(!state);
  };

  const handleSignIn = () => {
    navigate("/signin");
  };
  return (
    <nav
      className={
        navbar
          ? "fixed top-0 z-50 w-full h-[4.3rem] bg-white text-primary px-4 flex justify-between items-center text-xl"
          : "fixed top-0 z-50 w-full h-[4.3rem] bg-transparent text-white px-4 flex justify-between items-center text-xl"
      }
    >
      <div className="flex lg:hidden">
        <div className="flex items-center">
          <div
            onClick={handleMobileNav}
            className={
              isNavExpanded
                ? "w-7 h-6 flex flex-col hamburger open mt-1.5 cursor-pointer "
                : "w-7 h-6 flex flex-col hamburger mt-1.5 cursor-pointer "
            }
          >
            <span className="w-6 h-0.5 mb-1 bg-white ham-top"></span>
            <span className="w-4 h-0.5 mb-1 bg-white ham-mid"></span>
            <span className="w-2 h-0.5 bg-white ham-buttom"></span>
          </div>
          <Link to="/" className="flex items-center">
            <div className="justify-center items-center w-14 h-14">
              <img src={logo} alt="logo" className="" />
            </div>
          </Link>
        </div>
      </div>
      <Link
        to="/"
        className="items-center justify-center hidden lg:flex lg:w-1/4 lg:justify-start"
      >
        <div className="justify-center items-center">
          {navbar ? (
            <img src={logowtext} alt="logo" className="h-18 w-48" />
          ) : (
            <img src={logowtext2} alt="logo" className="h-18 w-48" />
          )}
        </div>
      </Link>
      <Navlinks isNavExpanded={isNavExpanded} />
      <div className="hidden lg:flex lg: lg:w-2/4 lg:justify-center lg:items-center">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? "mx-4 border-b-[.2rem] border-primary py-[1.2rem]"
              : "mx-4 relative nav hover:text-primary py-[0.1rem]"
          }
        >
          Home
        </NavLink>
        <div className="mx-4 cursor-pointer flex w-fit">
          <p>Services</p>{" "}
          {serve ? (
            <IoIosArrowUp className="pt-1 text-3xl" onClick={handleServices} />
          ) : (
            <IoIosArrowDown
              className="pt-1 text-3xl"
              onClick={handleServices}
            />
          )}
        </div>
        <NavLink
          to="/about"
          className={({ isActive }) =>
            isActive
              ? "mx-4 border-b-[.2rem] border-primary py-[1.2rem]"
              : "mx-4 relative nav hover:text-primary py-[0.1rem]"
          }
        >
          About
        </NavLink>
        <NavLink
          to="/plans"
          className={({ isActive }) =>
            isActive
              ? "mx-4 border-b-[.2rem] border-primary py-[1.2rem]"
              : "mx-4 relative nav hover:text-primary py-[0.1rem]"
          }
        >
          Plans
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) =>
            isActive
              ? "mx-4 border-b-[.2rem] border-primary py-[1.2rem]"
              : "mx-4 relative nav hover:text-primary py-[0.1rem]"
          }
        >
          Contact
        </NavLink>
      </div>
      <div
        className={
          serve
            ? "absolute top-[4rem] left-[30rem] z-50 bg-white flex flex-col border border-primary"
            : "hidden"
        }
      >
        <Link
          to="/services/forex"
          className="border-b border-black w-64 text-center py-6 text-primary font-semibold hover:bg-primary hover:text-white"
        >
          Forex Trading
        </Link>
        <Link
          to="/services/real-estate"
          className="border-b border-black w-64 text-center py-6 text-primary font-semibold hover:bg-primary hover:text-white"
        >
          Real Estate
        </Link>
        <Link
          to="/services/crypto"
          className="border-b border-black w-64 text-center py-6 text-primary font-semibold hover:bg-primary hover:text-white"
        >
          Cryptocurrency
        </Link>
      </div>
      <div className="lg:w-1/4 text-sm md:text-lg lg:flex lg:justify-end">
        <button
          onClick={handleSignIn}
          className="capitalize border border-primary px-5 py-1 lg:px-10 lg:py-1.5 cursor-pointer rounded-md hover:bg-primary hover:text-white"
        >
          sign in
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
