import React from "react";

type TitleProps = {
  title?: string;
  sub?: string;
  line?: string;
};

const Title: React.FC<TitleProps> = ({ title, sub, line }) => {
  return (
    <div>
      <div className="text-center">
        <p className="text-4xl lg:text-5xl font-thin font-sofia tracking-wide">
          {title}
        </p>
        <p className="text-blue pt-2 text-xl px-10 lg:px-72 font-light">
          {sub}
        </p>
      </div>
      {/* <div className="w-14 h-1 bg-gradient-to-r from-[#4895ef] via-[#4361ee] to-[#3f37c7] mx-auto mt-4"> */}
      <div className="w-14 h-1 bg-primary mx-auto mt-4">{line}</div>
    </div>
  );
};

export default Title;
