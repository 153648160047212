import React, { useEffect, useRef } from "react";

// Define the props type for the TradingViewWidget component
interface TradingViewWidgetProps {
  symbol?: string;
  interval?: string;
  width?: string | number;
  height?: string | number;
}

const TradingViewWidget: React.FC<TradingViewWidgetProps> = ({
  symbol = "NASDAQ:AAPL",
  interval = "D",
  width = "100%",
  height = 500,
}) => {
  const widgetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.TradingView && widgetRef.current) {
      new window.TradingView.widget({
        container_id: widgetRef.current.id,
        width: width,
        height: height,
        symbol: symbol,
        interval: interval,
        timezone: "Etc/UTC",
        theme: "light",
        style: "1",
        locale: "en",
        toolbar_bg: "#f1f3f6",
        enable_publishing: false,
        allow_symbol_change: true,
        hide_side_toolbar: false,
        show_popup_button: true,
        popup_width: "1000",
        popup_height: "650",
      });
    }
  }, [symbol, interval, width, height]);

  return <div ref={widgetRef} id="tradingview-widget" />;
};

export default TradingViewWidget;
