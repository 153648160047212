import { Link } from "react-router-dom";
import { services } from "../data/data";
import Title from "./Title";
const Services = () => {
  return (
    <section className="relative">
      <div className="shadow-2xl absolute bg-white mx-8 md:mx-20 p-4 md:p-8 text-lg rounded-xl top-[8rem] lg:top-[10rem] mx-auto z-20">
        Affiliate Program opens up additional opportunities for all our
        investors. By inviting your friends, acquaintances, relatives or
        colleagues to our affiliate program, You can earn a guaranteed decent
        reward for your work. Our affiliate program reward in the amount of 5%
        for every deposited funds of your referrals.
        <br /> <br />
        <Link to="/affiliate" className="text-blue">
          Learn More...
        </Link>
      </div>
      <div className="clip-rectangle bg-gradient-to-r from-[#4895ef] via-[#4361ee] to-[#3f37c7] h-[30rem] lg:h-[30rem] w-full">
        <p className="text-center py-16 text-3xl text-white font-sofia tracking-widest">
          Affiliate Program
        </p>
      </div>

      <div className="border border-grey-200 py-16">
        <div>
          <Title title="Our services" sub="Services we offer" />
        </div>
        <div className=" md:grid md:grid-cols-2 lg:grid-cols-3">
          {services.map((service) => (
            <div
              key={service.title}
              className="shadow-2xl mt-10 mx-auto bg-secondary max-w-[25rem]"
            >
              <div
                className="w-full border rounded-t-full bg-no-repeat bg-cover bg-center"
                style={{ backgroundImage: `url(${service.img})` }}
              >
                <img
                  src={service.img}
                  alt={service.title}
                  className=" border rounded-t-xl h-[20rem] w-full"
                />
              </div>
              <div className="p-6">
                <p className="text-xl font-semibold pb-3">{service.title}</p>
                <p>{service.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
