import { useNavigate } from "react-router-dom";
import { plans } from "../data/data";
import Button from "./Button";
import Title from "./Title";

const Investment = () => {
  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate("/signup");
  };
  return (
    <section>
      <div className="py-14">
        <Title title="Our Investment Plans" sub="Choose a preferred plan" />
        <div className="md:grid md:grid-cols-2 lg:grid-cols-4 bor rounded-xl my-10 mx-6 lg:mx-14">
          {plans.map((plan) => (
            <div
              key={plan.title}
              className="border border-blue bg-secondary text-center"
            >
              <div className="py-10 px-10 md:px-20 lg:px-10">
                <p className="font-semibold text-xl pt-10">{plan.price}</p>
                <p className="py-3">{plan.invest}</p>
                <p>{plan.percent}</p>
                <p className="pt-3">{plan.benefit}</p>
                <p className="py-3">{plan.trading}</p>
                <p className="pb-3">{plan.support}</p>
                <p className="font-semibold uppercase text-3xl pb-5">
                  {plan.title}
                </p>
                <Button
                  children="Sign up"
                  primary={true}
                  onClick={handleSignUp}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Investment;
