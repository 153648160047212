import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Withdrawal } from "../../interfaces/withdrawalInterface";

const API_URL = "https://signalpulse.vercel.app/user/";
// const API_URL = "/user/";

interface WithdrawalState {
  withdrawal: Withdrawal | [];
  error: boolean;
  success: boolean;
  loading: boolean;
  message: any;
}

const initialState: WithdrawalState = {
  withdrawal: [],
  error: false,
  message: "",
  success: false,
  loading: false,
};

//deposit
export const debit = createAsyncThunk<Withdrawal, Object>(
  "user/withdrawal",
  async (data, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.post(API_URL + "withdraw", data, config);
      return response.data;
    } catch (error: any) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get withdrawl
export const getUserDebit = createAsyncThunk<any>(
  "user/getUserDebit",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.get(API_URL + "withdraw", config);
      return response.data;
    } catch (error: any) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const debitSlice = createSlice({
  name: "debit",
  initialState,
  reducers: { reset: (state) => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(debit.pending, (state) => {
        state.loading = true;
      })
      .addCase(debit.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.withdrawal = action.payload;
      })
      .addCase(debit.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
      })

      //getUserDeposit
      .addCase(getUserDebit.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserDebit.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.withdrawal = action.payload;
      })
      .addCase(getUserDebit.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = debitSlice.actions;
const debitReducer = debitSlice.reducer;
export default debitReducer;
