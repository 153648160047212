// import CoinSlider from "../components/CoinSlider";
import AboutComp from "../components/AboutComp";
import Features from "../components/Features";
import Feedback from "../components/Feedback";
import Hero from "../components/Hero";
import Investment from "../components/Investment";
import ScrollUpButton from "../components/ScrollUp";
import Services from "../components/Services";
// import Tidio from "../components/Tidio";
import Tvr from "../components/Tvr";
import Verified from "../components/Verified";
import Why from "../components/Why";
import Layout from "../layouts/Layout";

const Home = () => {
  // const symbols = ["BTCUSD", "ETHUSD", "LTCUSD", "XRPUSD", "BCHUSD"];
  return (
    <Layout>
      {/* <CoinSlider symbols={symbols} /> */}
      <Hero />
      <AboutComp />
      <Features />
      <Why />
      <Investment />
      <Services />
      <Tvr symbol="NASDAQ:GOOGL" interval="D" />
      <Feedback />
      <Verified />
      <ScrollUpButton />
      {/* <Tidio /> */}
    </Layout>
  );
};

export default Home;
