import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { User } from "../../interfaces/userInterface";

const API_URL = "https://signalpulse.vercel.app/api/users/";
// const API_URL = "/api/users/";

interface UserState {
  user: User | null;
  authenticated: boolean;
  error: boolean;
  success: boolean;
  loading: boolean;
  message: any;
  checkingAuth: boolean;
}

interface UserData {
  newPassword?: string;
  confirmNewPassword?: string;
  email?: string;
  token?: string;
  verificationToken?: string;
}

const initialState: UserState = {
  user: null,
  // user: localStorage.getItem("user")
  //   ? JSON.parse(localStorage.getItem("user") as string)
  //   : null,
  error: false,
  authenticated: false,
  message: "",
  success: false,
  loading: false,
  checkingAuth: true,
};

// checkAuth
export const checkAuth = createAsyncThunk<User>(
  "users/checkAuth",
  async (_, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.get(API_URL + "check-auth", config);
      return response.data;
    } catch (error: any) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//signup user
export const signUp = createAsyncThunk<User | any, Object>(
  "users/signUp",
  async (userData, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };
      const response = await axios.post(API_URL + "signup", userData, config);
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//signin user
export const signIn = createAsyncThunk<User, string | Object>(
  "users/signIn",
  async (userData, thunkAPI) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    try {
      const response = await axios.post(API_URL + "signin", userData, config);
      localStorage.setItem("user", JSON.stringify(response.data));
      thunkAPI.dispatch(setLoggedIn(true));
      return response.data;
    } catch (error: any) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// verifyEmail
export const verifyEmail = createAsyncThunk<
  User,
  UserData,
  { rejectValue: string }
>("users/verifyEmail", async (userData, thunkAPI) => {
  const { verificationToken } = userData;
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    const response = await axios.post(
      API_URL + "verify-email",
      { verificationToken },
      config
    );
    // Type assertion for better type inference
    return response.data as User;
  } catch (error: any) {
    const message =
      error.response?.data?.message ||
      error.response?.data?.error ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//forgotPassword
export const forgotPassword = createAsyncThunk<User, UserData>(
  "users/forgotPassword",
  async (userData, thunkAPI) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    const { email } = userData;

    try {
      const response = await axios.patch(
        API_URL + `forgot-password`,
        email,
        config
      );
      return response.data;
    } catch (error: any) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//resetPassword
export const resetPassword = createAsyncThunk<User, UserData>(
  "users/resetPassword/:token",
  async (userData, thunkAPI) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    const { newPassword, confirmNewPassword, token } = userData;
    try {
      const body = JSON.stringify({
        password: newPassword,
        confirmPassword: confirmNewPassword,
      });

      const response = await axios.patch(
        API_URL + `reset-password/${token}`,
        body,
        config
      );
      localStorage.setItem("user", JSON.stringify(response.data));
      return response.data;
    } catch (error: any) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//logout user
export const logout = createAsyncThunk<void>(
  "users/logout",
  async (_, thunkAPI) => {
    try {
      localStorage.removeItem("user");
      thunkAPI.dispatch(setLoggedIn(false));
      // toast.info("Successfully Logged Out");
    } catch (error: any) {
      const message =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.success = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // checkAuth
      .addCase(checkAuth.pending, (state) => {
        state.checkingAuth = true;
        state.loading = true;
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.loading = false;
        state.checkingAuth = false;
        state.user = action.payload;
        state.authenticated = true;
        state.error = false;
        // Optionally save to localStorage if needed
        localStorage.setItem("user", JSON.stringify(action.payload));
      })
      .addCase(checkAuth.rejected, (state, action) => {
        state.loading = false;
        state.checkingAuth = false;
        state.error = true;
        state.authenticated = false;
      })

      // sign up
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.user = action.payload;
        state.authenticated = true;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.error = true;
        state.user = null;
        state.authenticated = false;
        state.message = action.payload;
      })

      //login
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.user = action.payload;
        state.authenticated = true;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.user = null;
        state.authenticated = false;
        state.message = action.payload;
      })

      // verifyEmail
      .addCase(verifyEmail.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.user = action.payload;
        state.authenticated = true;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.user = null;
        state.authenticated = false;
        state.message = action.payload;
      })

      // forgotPassword
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      })

      //resetPassword
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.user = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.user = null;
        state.message = action.payload;
      })

      //logout
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.authenticated = false;
      });
  },
});

export const { setLoggedIn } = userSlice.actions;
const userReducer = userSlice.reducer;
export default userReducer;
