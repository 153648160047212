import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../store";

type ProtectedType = {
  children: ReactNode;
};

const Protected = ({ children }: ProtectedType) => {
  const { user } = useSelector((state: RootState) => state.user);
  let location = useLocation();

  if (user === null) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  return <>{children}</>;
};
export default Protected;
