import { inject } from "@vercel/analytics";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Protected from "./components/Protected";
import Home from "./pages/Home";
import Spinner from "./utils/Spinner";

const About = lazy(() => import("./pages/About"));
const NotFound = lazy(() => import("./pages/NotFound"));
const TnC = lazy(() => import("./pages/TnC"));
const FAQ = lazy(() => import("./pages/FAQ"));
const Cryptocurrency = lazy(() => import("./pages/services/Cryptocurrency"));
const Affiliate = lazy(() => import("./pages/Affiliate"));
const Plans = lazy(() => import("./pages/Plans"));
const RealEstate = lazy(() => import("./pages/services/RealEstate"));
const Forex = lazy(() => import("./pages/services/Forex"));
const Contact = lazy(() => import("./pages/Contact"));
const Signin = lazy(() => import("./pages/Signin"));
const Signup = lazy(() => import("./pages/Signup"));
const Verify = lazy(() => import("./pages/Verify"));
const DashHome = lazy(() => import("./Dashboard/pages/Home"));
const Deposit = lazy(() => import("./Dashboard/pages/Deposit"));
const Withdraw = lazy(() => import("./Dashboard/pages/Withdraw"));
const History = lazy(() => import("./Dashboard/pages/History"));
const Investment = lazy(() => import("./Dashboard/pages/Investment"));
const Referral = lazy(() => import("./Dashboard/pages/Referral"));
const Withdrawal = lazy(() => import("./Dashboard/pages/Withdrawal"));
const Profile = lazy(() => import("./Dashboard/pages/Profile"));
const Payment = lazy(() => import("./Dashboard/pages/Payment"));
const Ethereum = lazy(() => import("./Dashboard/pages/Ethereum"));
const Matic = lazy(() => import("./Dashboard/pages/Matic"));
const TetherTRX = lazy(() => import("./Dashboard/pages/TetherTRX"));
const TetherERC = lazy(() => import("./Dashboard/pages/TetherERC"));
const Bitcoin = lazy(() => import("./Dashboard/pages/Bitcoin"));

function App() {
  inject();
  const shouldRedirect = true;

  return (
    <div className="font-outfit w-full overflow-hidden">
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/not-found" element={<NotFound />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/plans" element={<Plans />}></Route>
          <Route path="/faq" element={<FAQ />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/affiliate" element={<Affiliate />}></Route>
          <Route path="/terms&conditions" element={<TnC />}></Route>
          <Route path="/signin" element={<Signin />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route
            path="/verify-account"
            element={<Verify initialMinutes={5} initialSeconds={0} />}
          ></Route>

          <Route path="/services/real-estate" element={<RealEstate />}></Route>
          <Route path="/services/forex" element={<Forex />}></Route>
          <Route path="/services/crypto" element={<Cryptocurrency />}></Route>
          <Route
            path="/user/dashboard"
            element={
              <Protected>
                <DashHome />
              </Protected>
            }
          ></Route>
          <Route
            path="/user/deposit"
            element={
              <Protected>
                <Deposit />
              </Protected>
            }
          ></Route>
          <Route
            path="/user/withdraw"
            element={
              <Protected>
                <Withdraw />
              </Protected>
            }
          ></Route>
          <Route
            path="/user/history"
            element={
              <Protected>
                <History />
              </Protected>
            }
          ></Route>
          <Route
            path="/user/investment"
            element={
              <Protected>
                <Investment />
              </Protected>
            }
          ></Route>
          <Route
            path="/user/referral"
            element={
              <Protected>
                <Referral />
              </Protected>
            }
          ></Route>
          <Route
            path="/user/withdrawAccount"
            element={
              <Protected>
                <Withdrawal />
              </Protected>
            }
          ></Route>
          <Route
            path="/user/profile"
            element={
              <Protected>
                <Profile />
              </Protected>
            }
          ></Route>
          <Route
            path="/user/deposit/pay"
            element={
              <Protected>
                <Payment />
              </Protected>
            }
          ></Route>
          <Route
            path="/user/deposit/pay/ethereum"
            element={
              <Protected>
                <Ethereum />
              </Protected>
            }
          ></Route>
          <Route
            path="/user/deposit/pay/matic"
            element={
              <Protected>
                <Matic />
              </Protected>
            }
          ></Route>
          <Route
            path="/user/deposit/pay/bitcoin"
            element={
              <Protected>
                <Bitcoin />
              </Protected>
            }
          ></Route>
          <Route
            path="/user/deposit/pay/tether-trx"
            element={
              <Protected>
                <TetherTRX />
              </Protected>
            }
          ></Route>
          <Route
            path="/user/deposit/pay/tether-erc"
            element={
              <Protected>
                <TetherERC />
              </Protected>
            }
          ></Route>
          {/* <Route
            path="/"
            element={shouldRedirect && <Navigate replace to="/home" />}
          ></Route> */}
          <Route
            path="*"
            element={shouldRedirect && <Navigate replace to="/not-found" />}
          ></Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
