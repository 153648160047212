import { useNavigate } from "react-router-dom";
import rounded1 from "../assets/images/rounded-shape1.png";
import rounded2 from "../assets/images/rounded-shape2.png";
import { steps, why } from "../data/data";
import Button from "./Button";
import Header from "./Title";

const Why = () => {
  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate("/signup");
  };
  return (
    <section className="bg-secondary">
      <div>
        <div
          className="bg-no-repeat bg-auto bg-top-left"
          style={{ backgroundImage: `url(${rounded1})` }}
        >
          <div
            className="bg-no-repeat bg-auto bg-right-bottom"
            style={{ backgroundImage: `url(${rounded2})` }}
          >
            <div className="pt-20 px-10 md:px-20">
              <div className="text-center">
                <Header
                  title="Why Choose Us"
                  sub="Excellent for your business"
                />
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-x-10 lg:grid-cols-3">
                {why.map((us) => (
                  <div key={us.title} className="py-10 flex gap-x-6">
                    <div className="text-6xl text-blue">{us.icon}</div>
                    <div>
                      <p className="font-semibold pb-4">{us.title}</p>
                      <p>{us.desc}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="py-10 px-10 md:px-20">
              <div className="text-center">
                <Header
                  title="How it works"
                  sub="Simple steps to get started"
                />
              </div>
              <div className="md:grid md:gap-x-10 lg:grid-cols-3">
                {steps.map((step) => (
                  <div key={step.title} className="py-10 flex gap-x-6">
                    <h1 className="text-6xl bg-gradient-to-r from-[#4895ef] via-[#4361ee] to-[#3f37c7] inline-block text-transparent bg-clip-text">
                      {step.number}
                    </h1>
                    <div>
                      <p className="font-semibold pb-4 ">{step.title}</p>
                      <p>{step.desc}</p>
                      <div className="w-14 h-1 bg-gradient-to-r from-[#4895ef] via-[#4361ee] to-[#3f37c7] mt-4 md:text-lg">
                        {step.empty}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pt-10 flex justify-center">
                <Button
                  children="Sign up"
                  primary={true}
                  onClick={handleSignUp}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Why;
